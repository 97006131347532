<template>
  <v-form v-model="valid">
    <v-container class="pa-0" fluid>
      <v-row dense>
        <v-col cols="12">
          <v-select
            v-model="localFormData.MARITAL_STATUS"
            :rules="[rules.required]"
            :items="items"
            :label="$t('FORMS.CIVIL_STATUS.CHOOSE_STATUS') + ' *'"
            hide-details
            solo
            :append-icon="errors.marital_status ? 'mdi-alert-circle-outline' : 'mdi-chevron-down'"
            @update:error="errors = { ...errors, marital_status: $event }"
          ></v-select>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-if="!localFormData.LASTNAME_SAME"
            v-model="localFormData.NEW_LASTNAME"
            :rules="[rules.required]"
            :label="$t('FORMS.CIVIL_STATUS.NEW_LASTNAME') + ' *'"
            hide-details
            solo
            :append-icon="errors.new_last_name ? 'mdi-alert-circle-outline' : null"
            @update:error="errors = { ...errors, new_last_name: $event }"
          ></v-text-field>

          <v-checkbox
            v-model="localFormData.LASTNAME_SAME"
            :label="$t('FORMS.CIVIL_STATUS.LASTNAME_SAME')"
            hide-details
            :append-icon="errors.last_name_same ? 'mdi-alert-circle-outline' : null"
            @update:error="errors = { ...errors, last_name_same: $event }"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row v-if="localFormData.MARITAL_STATUS" class="mt-3 text-center">
        <v-col cols="12">
          <p
            v-if="localFormData.MARITAL_STATUS == $t('FORMS.CIVIL_STATUS.STATUSES.MARRIAGE')"
            class="red--text"
          >
            {{ $t("FORMS.CIVIL_STATUS.ADD_MARRIAGE_CERTIFICATE") + " *" }}
          </p>
          <p v-else>
            {{ $t("FORMS.CIVIL_STATUS.ADD_MARRIAGE_DIVORCE_CERTIFICATE") }}
          </p>
          <p>
            <v-btn class="font-weight-regular text-none _box-shadow" small @click="takePicture">
              <v-icon left>mdi-camera-outline</v-icon>
              {{ $t("FORMS.CIVIL_STATUS.ADD_CERTIFICATE") }}
            </v-btn>
          </p>

          <p v-if="imageElement.src">
            <img :src="`data:image/jpeg;base64, ${imageElement.src}`" width="100%" />
          </p>
        </v-col>
      </v-row>

      <v-row dense text-center>
        <v-col cols="12">
          <slot name="button" :valid="valid" />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <slot name="description" />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { Plugins, CameraResultType } from "@capacitor/core";
import Rules from "@/app/modules/_global/classes/Rules";

const { Camera } = Plugins;

export default {
  name: "CivilStatusForm",

  model: {
    prop: "formData"
  },

  props: {
    formData: {
      required: true,
      type: Object
    }
  },

  data: vm => ({
    localFormData: null,

    status: null,
    items: [
      vm.$t("FORMS.CIVIL_STATUS.STATUSES.MARRIAGE"),
      vm.$t("FORMS.CIVIL_STATUS.STATUSES.DIVORCE")
    ],

    imageElement: {
      src: ""
    },

    valid: true,
    errors: {},

    rules: {
      required: Rules.required
    }
  }),

  watch: {
    localFormData: {
      deep: true,
      handler: "setFormData"
    }
  },

  created() {
    this.localFormData = Object.assign({}, this.formData);
  },

  methods: {
    async takePicture() {
      const image = await Camera.getPhoto({
        width: 400,
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Base64
      });
      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      const imageUrl = image.base64String;
      // Can be set to the src of an image now
      this.imageElement.src = imageUrl;
      this.localFormData.picture = imageUrl;
    },

    setFormData() {
      this.$emit("input", this.localFormData);
    }
  }
};
</script>
